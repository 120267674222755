export function getClassNameByLang(language) {
	switch (language) {
		case 'Vietnamese':
			return 'vietnamese-font'
		case 'Romanian':
			return 'romanian-font'	

		default:
			return ''
	}
}