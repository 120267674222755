import Router from 'next/router';

// Component
import ModalWrapper from 'components/ModalWrapper';
import styled from 'styled-components';
import PriceContainer from 'components/PriceContainer';

// Utils
import truncateString from 'utils/truncate_string';
import { cancelEventBubble } from 'utils/cancel_event_bubble';
import { synchronizedSingleCartData } from 'utils/manipulate_array';
import { generateCoverImageUrl } from 'utils/generate_cover_image_url';
import BundleTypeBadge from '../bundles/BundleTypeBadge';

import { truncateText } from 'utils/helper';
import { getClassNameByLang } from 'utils/getClassByLang';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/button';
import { Heading, SubHeading, TextBody } from 'components/Typography/General';
import { FONT_TYPES } from 'variables/font_groups';
import { isMobile } from 'react-device-detect';
import { getDirectionByLanguage } from 'utils/get_direction';

const CardHorizontalImg = styled.div`
  grid-column: 1/2;
  width: 100%;
  aspect-ratio: 346/180;
  // height: 13.7rem;
  border-radius: 1rem;
  background-image: url('${({ src }) => src}');
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const ModalCheckout = (props) => {
  const { language } = props;
  const classNameByLang = getClassNameByLang(language);
  let cart = JSON.parse(localStorage.getItem('cart')) || [];

  let synchronizedCartData = [];

  if (cart.length > 0) {
    synchronizedCartData = cart.map((item) => {
      return synchronizedSingleCartData({ item: item, isProduct: item.isProduct ?? false });
    });
  }

  const displayedCartData = synchronizedCartData[synchronizedCartData.length - 1];

  const handleCheckout = () => {
    // when click checkout, redirect to cart page with all items in cart page is checked
    let cart = JSON.parse(localStorage.getItem('cart'));
    let localStorageArray = [];
    cart.forEach((item) => {
      let json = synchronizedSingleCartData({ item: item, isProduct: item.isProduct ?? false });
      localStorageArray.push(json);
    });

    localStorage.setItem('is-cart-checked-all', JSON.stringify(true));
    localStorage.setItem('cart-checked', JSON.stringify(localStorageArray));
    Router.push('/cart');
  };

  const handleGoToCourse = () => {
    let slug = localStorage.getItem('slug') || '';

    if (slug) Router.push(`/courses/${slug}`);
    else Router.reload(window.location.pathname);
  };

  return (
    <ModalWrapper {...props} isBottom>
      <div className="description-wrapper-modal">
        <div className="description-wrapper-modal__header">
          <div className="content">
            <Heading tag="div" variety={isMobile ? 3 : 2} className="title">
              Added to cart
            </Heading>
            <div className="d-flex align-items-center">
              <Heading tag="div" variety={isMobile ? 4 : 5} className="quantity">
                {synchronizedCartData.length} {synchronizedCartData.length > 1 ? 'Items' : 'Item'}
              </Heading>
              <div className="ms-4 ms-lg-5">
                <font
                  color="#323232"
                  className="p-0 font-size-24 cursor-pointer"
                  onClick={(e) => {
                    props.setOpen(false), Router.reload();
                  }}
                >
                  <strong>
                    <i className="bi bi-x d-flex"></i>
                  </strong>
                </font>
              </div>
            </div>
          </div>
        </div>
        <div className="description-wrapper-modal__content">
          <div className="card-horizontal-2 card-floating description-inside-modal">
            <div>
              <CardHorizontalImg src={generateCoverImageUrl(displayedCartData.image)} />
              <PriceContainer
                className="d-inline-block d-lg-none w-100"
                variableDiscount={[parseFloat(displayedCartData.discount)]}
                variablePrice={[parseFloat(displayedCartData.price)]}
                courseOrganizer={displayedCartData.provider}
                isLarge
              />
            </div>
            <div className="details">
              <SubHeading
                variety={isMobile ? 2 : 3}
                className={`title-2 ${classNameByLang}`}
                dir={getDirectionByLanguage([displayedCartData.language, language])}
              >
                {truncateText(displayedCartData.name, 100, true)}
              </SubHeading>
              {displayedCartData.isBundle && <BundleTypeBadge bundleType={displayedCartData.bundleType} />}
              <PriceContainer
                className="d-lg-inline-block d-none w-100"
                variableDiscount={[parseFloat(displayedCartData.discount)]}
                variablePrice={[parseFloat(displayedCartData.price)]}
                courseOrganizer={displayedCartData.provider}
                isLarge
              />
              <TextBody tag="div" variety={isMobile ? 3 : 5} className="title-3">
                Descriptions:
              </TextBody>
              <TextBody
                tag="p"
                variety={isMobile ? 3 : 5}
                dir={getDirectionByLanguage([displayedCartData.language, language])}
                className={`description ${classNameByLang}`}
                style={{ textWrap: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: truncateText(displayedCartData.description, 200, true) }}
              ></TextBody>
            </div>
          </div>
        </div>
        <div className="description-wrapper-modal__footer">
          {/* <button className="button button-outline" onClick={() => handleGoToCourse()}>Go to courses</button> */}
          <Button
            type={BUTTON_TYPE.secondary}
            variety={isMobile ? 4 : 5}
            fontType={FONT_TYPES.subHeading}
            className="font-weight-600"
            onClick={(e) => {
              cancelEventBubble(e);
              localStorage.removeItem('cart-checked');
              props.setOpen(false), Router.reload();
            }}
          >
            Continue Shopping
          </Button>
          <Button
            className="button-primary p-0"
            variety={isMobile ? 4 : 5}
            fontType={FONT_TYPES.subHeading}
            type={BUTTON_TYPE.primary}
            onClick={(e) => {
              cancelEventBubble(e);
              handleCheckout();
            }}
          >
            Checkout
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalCheckout;
