import { DIRECTION } from 'constants/direction';

export const getDirectionByLanguage = (language) => {
  const rtlDirectionLanguage = ['Arabic', 'ar'];

  return Array.isArray(language)
    ? rtlDirectionLanguage.some((item) => language.includes(item))
      ? DIRECTION.rtl
      : DIRECTION.ltr
    : rtlDirectionLanguage.includes(language)
    ? DIRECTION.rtl
    : DIRECTION.ltr;
};
